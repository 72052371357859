var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',{attrs:{"title":_vm.$t(_vm.title),"fields":_vm.fields,"url":_vm.url,"body":{ role: 1 }},scopedSlots:_vm._u([{key:"cell(id)",fn:function(data){return [(
          data.item.main_contact_person &&
          data.item.main_contact_person[0] &&
          data.item.main_contact_person[0].user
        )?_c('b-link',{staticClass:"badge badge-light-primary",staticStyle:{"width":"65px"},attrs:{"to":'/investor/' + data.item.main_contact_person[0].user.id}},[_c('span',[_vm._v(_vm._s(data.item.main_contact_person[0].user.id))])]):_c('span',[_vm._v("-")])]}},{key:"cell(created_at)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(data.item.created_at))+" ")]}},{key:"cell(name)",fn:function(data){return [(
          data.item.main_contact_person &&
          data.item.main_contact_person[0] &&
          data.item.main_contact_person[0].user
        )?_c('b-link',{attrs:{"to":'/investor/' + data.item.main_contact_person[0].user.id}},[_c('span',[_vm._v(_vm._s(data.item.main_contact_person[0].user.name)+" "+_vm._s(data.item.main_contact_person[0].user.last_name)+" ")])]):_c('span',[_vm._v("-")])]}},{key:"cell(email)",fn:function(data){return [(
          data.item.main_contact_person &&
          data.item.main_contact_person[0] &&
          data.item.main_contact_person[0].user
        )?_c('span',[_vm._v(_vm._s(data.item.main_contact_person[0].user.email))]):_vm._e()]}},{key:"cell(company_name)",fn:function(data){return [(
          data.item.main_contact_person &&
          data.item.main_contact_person[0] &&
          data.item.main_contact_person[0].user
        )?_c('span',{staticClass:"badge badge-light-primary",staticStyle:{"width":"55px"}},[_vm._v("C-"+_vm._s(data.item.main_contact_person[0].company_id))]):_c('span',[_vm._v("-")])]}},{key:"cell(status)",fn:function(data){return [(data.item && data.item.status == 1)?_c('span',{staticClass:"text-success"},[_vm._v(_vm._s(_vm.$t("active"))+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t("inactive"))+" ")])]}},{key:"cell(address)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.address_nr)+", "+_vm._s(data.item.city)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }